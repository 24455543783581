body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bitter", serif;
  color: white;
}

p,
input,
button {
  font-family: "Source Sans Pro", sans-serif;
  color: white;
}

.layout {
  padding-top: 86px;
}

@media (max-width: 959px) {
  .layout {
    padding-top: 0;
  }
}
